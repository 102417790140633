import React from "react";
import { Accordion, Modal, Tab, Tabs } from "react-bootstrap";

const GraphicDefaultModal = (props: any) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 className="mb-0 heading4-bold">TBD Default Graphic Setup</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="variables"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="variables" title="Variables">
            <Accordion defaultActiveKey="0" className="default-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>T022-FR1</Accordion.Header>
                <Accordion.Body>
                  <div className="default-graphics-table">
                    <div className="default-graphics-img">
                      <img
                        className="item-img"
                        src="https://stdrec.s3.amazonaws.com/assets/images/U101-T002.png"
                        alt=""
                      />
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Variable</th>
                          <th>Visiblity</th>
                          <th>Text Variables</th>
                          <th>Color Variables</th>
                          <th>Add Strokes</th>
                          <th>Stroke Color</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Text_1</td>
                          <td>
                            <button className="btn btn-outline-danger bg-transparent text-danger border-0">
                              <span className="fa-regular fa-eye"></span>
                            </button>
                            <button className="btn btn-outline-danger bg-transparent text-danger border-0">
                              <span className="fa-regular fa-eye-slash"></span>
                            </button>
                          </td>
                          <td>
                            <select>
                              <option>Stacked Name</option>
                              <option>Stacked Name</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Stacked Name</option>
                              <option>Stacked Name</option>
                            </select>
                          </td>
                          <td>
                            <div className="btn-group">
                              <button className="btn btn-sm">
                                <span className="fa-solid fa-minus"></span>
                              </button>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                              />
                              <button className="btn btn-sm">
                                <span className="fa-solid fa-plus"></span>
                              </button>
                            </div>
                          </td>
                          <td>
                            <select>
                              <option>Stacked Name</option>
                              <option>Stacked Name</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Accordion Item #2</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Accordion Item #2</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Tab>
          <Tab eventKey="graphicColors" title="Graphic colors" disabled>
            Tab content for Profile
          </Tab>
          <Tab eventKey="contact" title="Sized Art">
            Coming Soon
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger" onClick={props.onHide}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default GraphicDefaultModal;
