import { client } from "utils/client";

export const getPreSignedUrl = (params: { [key: string]: string }) => {
  return client.get("/utils/pre-signed-url", { params }).then((res) => res.data?.data);
};

export const uploadFileToPreSignedUrl = (url: string, file: File | Blob) => {
  const requestOptions = {
    method: "PUT",
    body: file,
    redirect: "follow",
  } as any;
  return fetch(url, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => {
      console.log(error)
      throw new Error(`Failed upload file`)
    });
};
