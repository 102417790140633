import { FC, useCallback, useEffect, useState } from "react";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import InventoryModal from "./InventoryModal";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import * as bodyColorService from "api/bodyColorService";
import { fetchLocalBlankGarments, startInventorySyncFromAM, checkInventorySyncStatus } from "api/inventoryService";
import CustomTooltip from "components/tooltip/Tooltip";
import ConfirmationModal from "components/confirmationModal/ConfirmationModal";
import BlankStyleFitModel from "./BlankStyleFit/BlankStyleFitModel";
import BlankStyleShippingModel from "./BlankStyleShipping/BlankStyleShippingModel";

const Inventory: FC = (): JSX.Element => {
  const [colorMap, setColorMap] = useState<any>({});
  const [garmentMap, setGarmentMap] = useState<any>({});
  const [action, setAction] = useState<'Add' | 'View'>('Add');
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [isInventorySync, setIsInventorySync] = useState<boolean>(false);
  const [blankGarment, setBlankGarment] = useState<any>(false);
  const [blankGarmentForShipping, setBlankGarmentForShipping] = useState<any>(false);
  const [blankGarments, setBlankGarments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDescOpen, setIsDescOpen] = useState<boolean>(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false);
  const { accessToken } = useAppSelector(getUserDetails);
  const isDescOpenV2 = Boolean(blankGarment)
  const isDescOpenV3 = Boolean(blankGarmentForShipping)

  const fetchBlankGarments = async () => {
    try {
      setIsLoading(true);
      const result = await fetchLocalBlankGarments(accessToken);
      if (!result?.data?.data.length) {
        console.error("Body color not Found");
      }
      const _garmentMap = result.data.data.reduce((a: any, c: any) => {
        a[c.productId] = c.styleNumber
        return a
      }, {})
      setGarmentMap(_garmentMap)
      setBlankGarments(result.data.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }

  const handleConfirmCallBack = async () => {
    try {
      setIsLoading(true);
      // await deleteBlankGarmentInventory(selectedId, accessToken);
      setIsLoading(false);
      fetchBlankGarments()
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }


  const handleCheckInventorySyncStatus = useCallback(async() => {
    try {
      const response = await checkInventorySyncStatus(accessToken)
      if (response.data.data !== 'ACTIVE') {
        setIsInventorySync(false);
      }
    } catch (err) {
      console.error(err);
      setIsInventorySync(false);
    }
  }, [setIsInventorySync, accessToken])

  const handleInventorySync = async () => {
    // if(isInventorySync) return
    try {
      setIsLoading(true);
      await startInventorySyncFromAM(accessToken)
      setIsInventorySync(true);
      setIsLoading(false);
    } catch (err: any) {
      console.error(err);
      setIsLoading(false)
      setIsInventorySync(false);
    }
  }

  useEffect(() => {
    if (isInventorySync) {
      const invtrySyncId = setInterval(handleCheckInventorySyncStatus, 1500)
      sessionStorage.setItem('inv_sync_id', String(invtrySyncId))
    } else {
      const invtrySyncId = sessionStorage.getItem('inv_sync_id')
      if(invtrySyncId){
        clearInterval(invtrySyncId)
      }
    }
  }, [isInventorySync, handleCheckInventorySyncStatus])

  useEffect(() => {
    async function fetchBodyColors() {
      try {
        setIsLoading(true);
        const result = await bodyColorService.fetchAllBodyColor(accessToken);
        if (!result?.data?.data.length) {
          console.error("Body color not Found");
        }
        const _colorMap = result.data.data.reduce((a: any, c: any) => {
          a[c.colorAbbr] = c.colorName;
          return a;
        }, {});
        setColorMap(_colorMap);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    }
    fetchBodyColors();
    fetchBlankGarments();
  }, [accessToken]);

  const openAddModal = () => {
    setAction('Add')
    setIsOpen(true)
  }

  const openViewModal = (id: number) => {
    setAction('View')
    setSelectedId(id)
    setIsOpen(true)
  }

  const closeModal = () => {
    setSelectedId(null)
    setIsOpen(false)
  }


  if (isLoading) {
    return <SpinnerLoader message={"Loading..."} />;
  }

  return (
    <div className="content-sm page-bottom-space">
      <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
        <h4 className="mb-0 heading4-bold">Inventory</h4>
        <button
          type="button"
          className="btn btn-link"
          onClick={handleInventorySync}
          disabled={isLoading || isInventorySync}
        >
          <i className="fa-solid fa-refresh text-danger me-2"></i>
          <span className="text-dark">
            {(isLoading || isInventorySync) ? 'Syncing From AM...' : 'Sync From AM'}
          </span>
        </button>
        <button
          type="button"
          className="btn btn-link"
          onClick={openAddModal}
        >
          <i className="fa-solid fa-circle-plus text-danger me-2"></i>
          <span className="text-dark">New Blank Garment</span>
        </button>
      </div>
      {blankGarments.length > 0 && (
        <ul className="list-group list-group-flush list-style-none mb-2">
          {blankGarments.map((garment: any) => {
            return (
              <li key={garment.id}>
                <div className="has-right-action d-flex align-items-center">
                  <div onClick={() => {}}>
                    <p className="mb-0 text-capitalize">
                      {garment.styleNumber}&nbsp;&nbsp;({garment.description})
                    </p>
                  </div>
                  <div className="action-wrap">
                    <CustomTooltip msg={"Shipping"}>
                      <button
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() => {
                          setBlankGarmentForShipping(garment);
                        }}
                      >
                        <i className="fa-solid fa-shipping-fast">{""}</i>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Inventory"}>
                      <button
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() => openViewModal(garment.productId)}
                      >
                        <i className="fa-solid fa-box-open">{""}</i>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Fit"}>
                      <button
                        style={{ fontSize: '18px' }}
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() => {
                          setBlankGarment(garment);
                        }}
                      >
                        <span className="icon-apparel"></span>
                      </button>
                    </CustomTooltip>
                    <CustomTooltip msg={"Delete Template"}>
                      <button
                        style={{ display: 'none' }}
                        className="btn btn-link-danger ms-3"
                        type="button"
                        onClick={() => {
                          setIsOpenConfirmation(true);
                          setSelectedId(garment.productId);
                        }}
                      >
                        <i className="fa-solid fa-trash-can">{""}</i>
                      </button>
                    </CustomTooltip>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {isOpen && (
        <InventoryModal
          closeModal={closeModal}
          accessToken={accessToken}
          colorMap={colorMap}
          action={action}
          selectedId={selectedId}
          garmentMap={garmentMap}
          fetchBlankGarments={fetchBlankGarments}
          open
        />
      )}
      {isOpenConfirmation && (
        <ConfirmationModal
          open={isOpenConfirmation}
          setOpen={setIsOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
      {isDescOpenV2 && (
        <BlankStyleFitModel
          blankGarment={blankGarment}
          handleClose={() => setBlankGarment(null)}
        />
      )}
      {isDescOpenV3 && (
        <BlankStyleShippingModel
          blankGarment={blankGarmentForShipping}
          handleClose={() => setBlankGarmentForShipping(null)}
        />
      )}
    </div>
  );
};

export default Inventory;
